import React, { useEffect, useState } from "react";
import {
  H4,
  H6,
  H7,
  LoadingIcon,
  Text,
} from "../../components/textStyles/style";
import { Input } from "../../components/Inputs";
import { ButtonPrimary } from "../../components/buttonStyles/style";
import styled from "styled-components";
import axios from "axios";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Receipt } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import "../../styles/index.css";
import { Divider, FormHelperText } from "@material-ui/core";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Avenants from "./Avenants";
import AddInvoiceModal from "../../components/modals/AddInvoiceModal";
const MySwal = withReactContent(Swal);

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const CompanyFiles = ({ company_id, mode }) => {
  const history = useHistory();
  const [name, setName] = useState("");
  const [file, setFile] = useState(null);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [amountSub, setAmountSub] = useState("");
  const [amountCoaching, setAmountCoaching] = useState("");
  const [amountTrails, setAmountTrails] = useState("");
  const [amountEbook, setAmountEbook] = useState("");
  const [startDate, setStartDate] = useState("");
  const [ebooks, setEbooks] = useState("");
  const [endDate, setEndDate] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [maxUsers, setMaxUsers] = useState("");
  const [numberTrails, setNumberTrails] = useState("");
  const [maxCoaching, setMaxCoaching] = useState("");
  const [contracts, setContracts] = useState([]);
  const [selectedContract, setSelectedContract] = useState(null);

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
  };

  const handleSubmit = async (event) => {
    if (!submitLoader) {
      if (name.length < 2) {
        MySwal.fire({
          title: <H4>Oops...</H4>,
          html: <Text>Wrong name. Please choose a contract name</Text>,
          iconColor: "#FD7064",
          icon: "error",
          confirmButtonText: <Text color="white">Try again</Text>,
          confirmButtonColor: "#FD7064",
          confirmButtonAriaLabel: "Try again",
        });
      } else if (file == null) {
        MySwal.fire({
          title: <H4>Oops...</H4>,
          html: <Text>Please select a pdf file to upload.</Text>,
          iconColor: "#FD7064",
          icon: "error",
          confirmButtonText: <Text color="white">Try again</Text>,
          confirmButtonColor: "#FD7064",
          confirmButtonAriaLabel: "Try again",
        });
      } else if (amountTrails === "") {
        MySwal.fire({
          title: <H4>Oops...</H4>,
          html: <Text>Please insert an amount for the trails access</Text>,
          iconColor: "#FD7064",
          icon: "error",
          confirmButtonText: <Text color="white">Try again</Text>,
          confirmButtonColor: "#FD7064",
          confirmButtonAriaLabel: "Try again",
        });
      } else if (amountCoaching === "") {
        MySwal.fire({
          title: <H4>Oops...</H4>,
          html: <Text>Please insert an amount for the Coaching</Text>,
          iconColor: "#FD7064",
          icon: "error",
          confirmButtonText: <Text color="white">Try again</Text>,
          confirmButtonColor: "#FD7064",
          confirmButtonAriaLabel: "Try again",
        });
      } else if (amountEbook === "") {
        MySwal.fire({
          title: <H4>Oops...</H4>,
          html: <Text>Please insert an amount for the Ebooks</Text>,
          iconColor: "#FD7064",
          icon: "error",
          confirmButtonText: <Text color="white">Try again</Text>,
          confirmButtonColor: "#FD7064",
          confirmButtonAriaLabel: "Try again",
        });
      } else if (amountSub === "") {
        MySwal.fire({
          title: <H4>Oops...</H4>,
          html: <Text>Please insert an amount for Users access</Text>,
          iconColor: "#FD7064",
          icon: "error",
          confirmButtonText: <Text color="white">Try again</Text>,
          confirmButtonColor: "#FD7064",
          confirmButtonAriaLabel: "Try again",
        });
      } else {
        const fileExtension = file.name.split(".").pop();
        if (fileExtension === "pdf") {
          setSubmitLoader(true);
          event.preventDefault();

          const formData = new FormData();
          formData.append("file", file);
          formData.append("name", name);
          formData.append("amount_sub", amountSub);
          formData.append("amount_coaching", amountCoaching);
          formData.append("amount_trails", amountTrails);
          formData.append("amount_ebook", amountEbook);
          formData.append("startDate", startDate);

          formData.append("endDate", endDate);
          formData.append("max_users", Number(maxUsers));
          formData.append("max_coaching", Number(maxCoaching));
          formData.append("number_trails", Number(numberTrails));
          formData.append("ebooks", ebooks);
          try {
            await axios.post(
              `${process.env.REACT_APP_CONTRACTS}?company_id=${company_id}`,
              formData
            );
            history.go(0);
            setSubmitLoader(false);
          } catch (error) {
            if (error.response && error.response.status === 409) {
              MySwal.fire({
                title: <H4>Oops...</H4>,
                html: (
                  <Text>Invalid Contract Period! Please check Dates field</Text>
                ),
                iconColor: "#FD7064",
                icon: "error",
                confirmButtonText: <Text color="white">Try again</Text>,
                confirmButtonColor: "#FD7064",
                confirmButtonAriaLabel: "Try again",
              });
            }
            setSubmitLoader(false);
            console.log(error);
          }
        } else {
          MySwal.fire({
            title: <H4>Oops...</H4>,
            html: <Text>Wrong Document. Only PDF files are accepted!</Text>,
            iconColor: "#FD7064",
            icon: "error",
            confirmButtonText: <Text color="white">Try again</Text>,
            confirmButtonColor: "#FD7064",
            confirmButtonAriaLabel: "Try again",
          });
        }
      }
    }
  };

  const deleteHandler = async () => {
    if (!deleteLoader) {
      setDeleteLoader(true);

      axios
        .delete(
          `${process.env.REACT_APP_CONTRACTS}?contract_id=${selectedFile._id}`
        )
        .then((res) => {
          setDeleteLoader(false);
          history.go(0);
        })
        .catch((err) => {
          console.log(err);
          setDeleteLoader(false);
        });
    }
  };

  useEffect(() => {
    const getContracts = async () => {
      axios
        .get(`${process.env.REACT_APP_CONTRACTS}?company_id=${company_id}`)
        .then((res) => {
          setContracts(res.data);
        });
    };
    getContracts();
  }, [company_id]);

  return (
    <div style={{ marginTop: 20 }}>
      <H6 style={{ fontSize: 20 }}>
        4. Contracts - <H6 color="#807e7c">{contracts.length} contract(s)</H6>
      </H6>
      <div>
        {contracts.length === 0 ? (
          <div>
            <H7 style={{ marginTop: 10, fontSize: 17 }} color="#333333">
              No contract uploaded.
            </H7>
            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          </div>
        ) : (
          <div style={{ marginTop: 40 }}>
            {contracts.map((contract) => {
              return (
                <div
                  style={{
                    backgroundColor: "white",

                    padding: 10,
                    marginBottom: 30,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: 15,
                      paddingBottom: 5,
                    }}
                    key={contract._id}
                  >
                    <div style={{ display: "flex", alignItems: "flex-start" }}>
                      <div>
                        <Receipt
                          style={{
                            fontSize: 30,
                            color: "#1b4965",
                            marginRight: 5,
                          }}
                        />
                      </div>
                      <div>
                        <Typography
                          color="#1b4965"
                          fontWeight="600"
                          fontSize={14}
                          textTransform="capitalize"
                        >
                          Contract Reference : {contract.name}
                        </Typography>
                        <Typography color="#444" fontSize={14}>
                          Created at :
                          {" " +
                            moment(contract.createdAt).format(
                              "DD-MM-YYYY HH:mm"
                            )}
                        </Typography>
                        <Typography color="#444" fontSize={14}>
                          Period :{" "}
                          {moment(contract.startDate).format("DD-MM-YYYY")} |{" "}
                          {moment(contract.endDate).format("DD-MM-YYYY")}
                        </Typography>
                        <Typography color="#444" fontSize={14}>
                          Amount Users access :{" "}
                          {Number(contract.amount_sub).toFixed(2)} €
                        </Typography>
                        <Typography color="#444" fontSize={14}>
                          Amount Flash Coaching :{" "}
                          {Number(contract.amount_coaching).toFixed(2)} €
                        </Typography>
                        <Typography color="#444" fontSize={14}>
                          Amount Trails :{" "}
                          {Number(contract.amount_trails).toFixed(2)} €
                        </Typography>
                        <Typography color="#444" fontSize={14}>
                          Amount Ebooks :{" "}
                          {Number(contract.amount_ebook).toFixed(2)} €
                        </Typography>
                        {contract.max_users && (
                          <Typography color="#444" fontSize={14}>
                            Max users : {contract.max_users} user(s)
                          </Typography>
                        )}
                        {contract.number_trails && (
                          <Typography color="#444" fontSize={14}>
                            Trails access : {contract.number_trails} trail(s)
                          </Typography>
                        )}
                        <Typography color="#444" fontSize={14}>
                          Ebooks : {contract.ebooks} ebook(s)
                        </Typography>
                        <Typography color="#444" fontSize={14}>
                          Flash Coaching : {contract.max_coaching}{" "}
                          appointment(s)
                        </Typography>
                      </div>
                    </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Link href={contract.url} target="_blank">
                        View Contract
                      </Link>

                      <Button
                        style={{ marginLeft: 20 }}
                        variant="outlined"
                        onClick={() => {
                          setSelectedFile(contract);
                          setDeleteModal(true);
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row-reverse",
                      marginBottom: 10,
                    }}
                  >
                    <ButtonPrimary
                      onClick={() => {
                        setSelectedContract(contract._id);
                        setShowInvoiceModal(true);
                      }}
                    >
                      Add Invoice
                    </ButtonPrimary>
                  </div>
                  {contract.invoices && (
                    <div>
                      {contract.invoices.length === 0 ? (
                        <H7
                          style={{
                            marginTop: 10,
                            fontSize: 16,
                            marginLeft: 40,
                            marginBottom: 15,
                          }}
                          color="#333333"
                        >
                          No invoices uploaded.
                        </H7>
                      ) : (
                        // list invoices here
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                align="center"
                                style={{
                                  fontWeight: "bold",
                                  color: "#1B4965",
                                }}
                              >
                                Date
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontWeight: "bold",
                                  color: "#1B4965",
                                }}
                              >
                                Invoice Reference
                              </TableCell>

                              <TableCell
                                align="center"
                                style={{
                                  fontWeight: "bold",
                                  color: "#1B4965",
                                }}
                              >
                                Amount in €
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontWeight: "bold",
                                  color: "#1B4965",
                                }}
                              >
                                Invoice Link
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {contract.invoices.map((invoice) => {
                              return (
                                <TableRow key={invoice._id}>
                                  <TableCell align="center">
                                    {invoice.date}
                                  </TableCell>
                                  <TableCell align="center">
                                    {invoice.name ? invoice.name : "-"}
                                  </TableCell>
                                  <TableCell align="center">
                                    {Number(invoice.amount).toFixed(2)} €
                                  </TableCell>
                                  <TableCell align="center">
                                    <Link href={invoice.url} target="_blank">
                                      View Invoice
                                    </Link>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      )}
                    </div>
                  )}
                  {mode === "agreement" && (
                    <Avenants contract={contract} company_id={company_id} />
                  )}
                </div>
              );
            })}

            <Divider style={{ marginTop: 10 }} />
          </div>
        )}
        <div style={{ marginTop: 20 }}></div>
        <H6 style={{ fontSize: 18 }}>Create a contract</H6>
        <div style={{ marginLeft: 10 }}>
          <Row style={{ marginTop: 10 }}>
            <div style={{ width: "100%" }}>
              <H7 style={{ marginBottom: 8, fontSize: 17 }}>Start Date *</H7>
              <Input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div style={{ width: "100%" }}>
              <H7 style={{ marginBottom: 8, fontSize: 17 }}>End Date *</H7>
              <Input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Input
              placeholder="Contract name *"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Input
              type="number"
              placeholder="Max number of users *"
              value={maxUsers}
              onChange={(e) => setMaxUsers(e.target.value)}
            />
            <Input
              type="number"
              placeholder="Ebooks Number *"
              value={ebooks}
              onChange={(e) => setEbooks(e.target.value)}
            />
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Input
              placeholder="Coaching number *"
              value={maxCoaching}
              onChange={(e) => setMaxCoaching(e.target.value)}
            />
            <Input
              type="number"
              placeholder="Trails number access *"
              value={numberTrails}
              onChange={(e) => setNumberTrails(e.target.value)}
            />
          </Row>
          <Row style={{ marginTop: 20 }}>
            <div style={{ width: "100%" }}>
              <Input
                type="number"
                placeholder="amount Subscriptions in € (HT)*"
                value={amountSub}
                onChange={(e) => setAmountSub(e.target.value)}
              />
            </div>
            <div style={{ width: "100%" }}>
              <Input
                type="number"
                placeholder="amount Flash Coaching in € (HT)*"
                value={amountCoaching}
                onChange={(e) => setAmountCoaching(e.target.value)}
              />
            </div>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <div style={{ width: "100%" }}>
              <Input
                type="number"
                placeholder="amount Trails access in € (HT)*"
                value={amountTrails}
                onChange={(e) => setAmountTrails(e.target.value)}
              />
            </div>
            <div style={{ width: "100%" }}>
              <Input
                type="number"
                placeholder="amount Ebook in € (HT)*"
                value={amountEbook}
                onChange={(e) => setAmountEbook(e.target.value)}
              />
            </div>
          </Row>
          <Row>
            <div style={{ width: "100%", marginTop: 20 }}>
              <Input id="file" type="file" onChange={handleFileChange} />

              <FormHelperText>Only PDF files are allowed.</FormHelperText>
            </div>
          </Row>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div style={{ width: 300, marginTop: 15 }}>
              <ButtonPrimary
                width={300}
                style={{ marginLeft: 20 }}
                onClick={handleSubmit}
                type="submit"
              >
                {submitLoader ? (
                  <LoadingIcon color="#fff" />
                ) : (
                  " Add new contract"
                )}
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </div>

      {/** dialog to add invoice */}
      <AddInvoiceModal
        company_id={company_id}
        isOpen={showInvoiceModal}
        setSelectedContract={setSelectedContract}
        selectedContract={selectedContract}
        setIsOpen={setShowInvoiceModal}
      />
      {/** dialog to confirm delete */}
      <Dialog
        open={deleteModal}
        onClose={setDeleteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete this contract?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to permanently delete this contract?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "10px" }}>
          <Button onClick={deleteHandler}>
            {deleteLoader ? <LoadingIcon /> : "Delete"}
          </Button>
          <Button autoFocus onClick={() => setDeleteModal(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CompanyFiles;
