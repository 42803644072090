export const languages = [
  { value: "fr", label: "Français" },
  { value: "en", label: "English" },
];

export const languagesWithAll = [
  { value: "all", label: "All" },
  { value: "fr", label: "Français" },
  { value: "en", label: "English" },
];

export const categories = [
  { value: "Basic", label: "Basic" },
  { value: "Advanced", label: "Advanced" },
  { value: "Curious", label: "Curious" },
];

export const reportTypeOptions = [
  { value: "full", label: "Full Report" },
  { value: "light", label: "Light Report" },
]

export const types = [
  { value: "science", label: "Science" },
  { value: "conscience", label: "Consciousness" },
  { value: "body", label: "Body" },
  {
    value: "fun",
    label: "Fun",
  },
  { value: "rituals", label: "Rituals" },
  { value: "testimony", label: "Testimonials" },
  { value: "tools", label: "Toolbox" },
];



export const funSubTypes = [
  { value: "play", label: "Jouer" },
  { value: "sleep", label: "Dormir" },
  { value: "humor", label: "Humour" },
];

export const genderOptions = [
  { value: "NP", label: "NP." },
  { value: "F", label: "Mme." },
  { value: "H", label: "Mr." },
];

export const expertCategories = [
  {
    value: "1",
    label: "Experts-Coachs",
  },
  { value: "10", label: "Expert-Chat" },
  { value: "2", label: "Auteurs-trails" },
  { value: "3", label: "Scriptwriter" },
  { value: "4", label: "Thérapeute-Atelier" },
  { value: "5", label: "Studio Son" },
  { value: "6", label: "Studio Image" },
  { value: "7", label: "Studio Traduction" },
  { value: "8", label: "Acteur" },
  { value: "9", label: "Ecrivain" },
];

export const companiesPaymentOptions = [
  { label: "Test", value: "test" },
  { label: "Prepaid", value: "prepaid" },
  { label: "CY24 - Annual Plan (24.99)", value: "cy24" },
  { label: "CY35 - Annual Plan (34.99)", value: "cy35" },
];

export const sectorsOptions = [
  {
    label: "Agriculture/Agrifood",
    value: "agriculture",
  },
  {
    label: "Association",
    value: "association",
  },
  { label: "Energy", value: "energy" },
  { label: "Finance/Insurance", value: "finance" },
  { label: "Health", value: "health" },
  { label: "Industry", value: "industry" },
  { label: "Press", value: "press" },
  { label: "Public/Ministries", value: "public" },
  {
    label: "Services",
    value: "services",
  },
  { label: "Telecom/IT", value: "telecom" },
  { label: "Tourism", value: "tourism" },
  { label: "Trade/Craft", value: "trade" },
  { label: "Other", value: "other" },
];

export const companyTypologieOptions = [
  { label: "Client", value: "client" },
  { label: "Prospect", value: "prospect" },
];

export const ageOptions = [
  { value: "NP", label: "Not precise (NP)" },
  { value: "18-25", label: "18-25" },
  { value: "26-35", label: "26-35" },
  { value: "36-45", label: "36-45" },
  { value: "46-55", label: "46-55" },
  { value: ">56", label: "More than 56" },
];

export const appointmentStatus = [
  { value: -1, label: "All" },
  { value: 0, label: "New Appointment " },
  { value: 1, label: "Waiting Customer's Confirmation" },
  { value: 2, label: "Appointment Valid" },
  { value: 3, label: "Appointment Finished" },
  { value: 4, label: "Canceled By Expert" },
  { value: 5, label: "Canceled By User" },
  { value: 6, label: "User did not attend" },
];

export const appointmentStatusWithoutAll = [
  { value: 0, label: "New Appointment " },
  { value: 1, label: "Waiting Customer's Confirmation" },
  { value: 2, label: "Appointment Valid" },
  { value: 3, label: "Appointment Finished" },
  { value: 4, label: "Canceled By Expert" },
  { value: 5, label: "Canceled By User" },
  { value: 6, label: "User did not attend" },
];
export const appointmentStatusToExpert = [
  { value: 0, label: "New Appointment " },
  { value: 1, label: "Waiting Customer's Confirmation" },
  { value: 2, label: "Appointment Valid" },
  { value: 3, label: "Appointment Finished" },
  { value: 4, label: "Cancelled By Expert" },
  { value: 6, label: "User did not attend" },
];

export const refundOptions = [
  { value: "all", label: "All" },
  { value: "refunded", label: "Refunded" },
  { value: "to_refund", label: "To Refund" },
  { value: "not_refunded", label: "Not Refundable" },
  { value: "company", label: "Via Company contract" },

];

export const FAQCategories = [
  {
    label: "Facturation & Paiement",
    value: "facturation",
  },
  {
    label: "Application",
    value: "technique",
  },
  {
    label: "Trails",
    value: "trail",
  },
  {
    label: "Ateliers",
    value: "workshop",
  },
  {
    label: "Autres",
    value: "autres",
  },
];

export const FAQFacturationSubCategories = [
  { value: "billing", label: "Facturation" },
  { value: "payment", label: "Paiement" },
];

export const FAQTechniqueSubCategories = [
  {
    value: "dysfunction",
    label: "Dysfonctionnement",
  },
  { value: "suggestion", label: "Suggestion" },
];

export const FAQWorkshopsSubCategories = [
  {
    value: "all_workshops",
    label: "Toutes Catégories",
  },
  { value: "science", label: "Science" },
  { value: "conscience", label: "Conscience" },
  { value: "body", label: "Corps" },
  {
    value: "fun",
    label: "Fun",
  },
  { value: "rituals", label: "Rituels" },
  { value: "testimony", label: "Témoignage" },
  { value: "tools", label: "Outlis" },
  { value: "coaching", label: "Coaching" },
];

export const AllFAQSubCategories = [
  { value: "billing", label: "Facturation" },
  { value: "payment", label: "Paiement" },
  {
    value: "dysfunction",
    label: "Dysfonctionnement",
  },
  { value: "suggestion", label: "Suggestion" },
  {
    value: "all_workshops",
    label: "Toutes Catégories",
  },
  { value: "science", label: "Science" },
  { value: "conscience", label: "Conscience" },
  { value: "body", label: "Corps" },
  {
    value: "fun",
    label: "Fun",
  },
  { value: "rituals", label: "Rituels" },
  { value: "testimony", label: "Témoignage" },
  { value: "tools", label: "Outlis" },
  { value: "coaching", label: "Coaching" },
  { value: "all_trails", label: "Tous les Trails" },
];

export const CommunityPostStatus = [
  { value: "pending", label: "Non approuvée" },
  { value: "active", label: "Approuvée" },
  { value: "rejected", label: "Rejetée" },
];

export const CommunityRejectionReasons = [
  {
    label: "Non conforme à la politique d’échanges sur la communauté ",
    value: "Non conforme à la politique d’échanges sur la communauté ",
  },
  {
    label: "Ne concerne pas le trail de la communauté",
    value: "Ne concerne pas le trail de la communauté",
  },
  {
    label: "Autres",
    value: "Autres",
  },
];

export const srtOptions = [
  { value: "FR", label: "FR - Français" },
  { value: "EN", label: "EN - English" },
];

export const usersRolesOptions = [
  { value: "webmaster", label: "Webmaster" },
  { value: "sales", label: "Sales" },
  { value: "admin", label: "Administrator" },
  { value: "content_manager", label: "Content Manager" },
  { value: "financial", label: "Financial" },
  { value: "moderator", label: "Moderator" },
  { value: "expert", label: "Expert" },
];

export const monthsOptions = [
  { value: "all", label: "All" },
  { value: "1", label: "January" },
  { value: "2", label: "February" },
  { value: "3", label: "March" },
  { value: "4", label: "April" },
  { value: "5", label: "May" },
  { value: "6", label: "June" },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

export const monthsOptionsWithoutAll = [

  { value: "1", label: "January" },
  { value: "2", label: "February" },
  { value: "3", label: "March" },
  { value: "4", label: "April" },
  { value: "5", label: "May" },
  { value: "6", label: "June" },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

export const yearsOptions = [
  { value: "all", label: "All" },
  { value: "2022", label: "2022" },
  { value: "2023", label: "2023" },
  { value: "2024", label: "2024" },
  { value: "2025", label: "2025" },
  { value: "2026", label: "2026" },
  { value: "2027", label: "2027" },
  { value: "2028", label: "2028" },
  { value: "2029", label: "2029" },
  { value: "2030", label: "2030" },
];
export const yearsOptionsWithoutAll = [

  { value: "2022", label: "2022" },
  { value: "2023", label: "2023" },
  { value: "2024", label: "2024" },
  { value: "2025", label: "2025" },
  { value: "2026", label: "2026" },
  { value: "2027", label: "2027" },
  { value: "2028", label: "2028" },
  { value: "2029", label: "2029" },
  { value: "2030", label: "2030" },
];

export const coachingMailsOptions = [
  { value: "planification", label: "Planification Appointment" },
  { value: "other", label: "Other (blank)" },
];

export const daysOptions = [
  { value: 0, label: "Sunday" },
  { value: 1, label: "Monday" },
  { value: 2, label: "Tuesday" },
  { value: 3, label: "Wednesday" },
  { value: 4, label: "Thursday" },
  { value: 5, label: "Friday" },
  { value: 6, label: "Saturday" },
];

export const hoursOptions = [
  { value: "08:00", label: "08:00" },
  { value: "08:30", label: "08:30" },
  { value: "09:00", label: "09:00" },
  { value: "09:30", label: "09:30" },
  { value: "10:00", label: "10:00" },
  { value: "10:30", label: "10:30" },
  { value: "11:00", label: "11:00" },
  { value: "11:30", label: "11:30" },
  { value: "12:00", label: "12:00" },
  { value: "12:30", label: "12:30" },
  { value: "13:00", label: "13:00" },
  { value: "13:30", label: "13:30" },
  { value: "14:00", label: "14:00" },
  { value: "14:30", label: "14:30" },
  { value: "15:00", label: "15:00" },
  { value: "15:30", label: "15:30" },
  { value: "16:00", label: "16:00" },
  { value: "16:30", label: "16:30" },
  { value: "17:00", label: "17:00" },
  { value: "17:30", label: "17:30" },
  { value: "18:00", label: "18:00" },
  { value: "18:30", label: "18:30" },
  { value: "19:00", label: "19:00" },
  { value: "19:30", label: "19:30" },
  { value: "20:00", label: "20:00" },
  { value: "20:30", label: "20:30" },
  { value: "21:00", label: "21:00" },
  { value: "21:30", label: "21:30" },
];

export const subscriptionsOptions = [
  { label: "All", value: "all" },
  { label: "Freemium", value: "freemium" },
  { label: "Company", value: "company" },
  { label: "Monthly (Stripe)", value: "sub_one_month" },
  { label: "Three months (Stripe)", value: "sub_three_months" },
  { label: "Annual (Stripe)", value: "sub_annual" },
  { label: "Lifetime (Stripe)", value: "sub_lifetime" },
  { label: "Monthly (IAP)", value: "iap_sub_one_month" },
  { label: "Three months (IAP)", value: "iap_sub_three_months" },
  { label: "Annual (IAP)", value: "iap_sub_annual" },
  { label: "Lifetime (IAP)", value: "iap_sub_lifetime_gift" },
  { label: "Monthly (Gift)", value: "sub_one_month_gift" },
  { label: "Three months (Gift)", value: "sub_three_months_gift" },
  { label: "Annual (Gift)", value: "sub_annual_gift" },
  { label: "Lifetime (Gift)", value: "sub_lifetime_gift" },
];

export const sourceInvoicesOptions = [
  { label: "All", value: "all" },
  { label: "Subscription", value: "Subscription" },
  { label: "Buy Flash Coaching", value: "Buy Flash Coaching" },
  { label: "Lifetime Plan", value: "Lifetime Plan" },
  { label: "Buy E-book", value: "Buy Ebook" },
  { label: "Buy Trails", value: "Buy Trails" },
  { label: "Gift Plan", value: "Gift Plan" },
];

export const contactObject = [
  { label: "Billing & Payments", value: "facturation" },
  { label: "Technical Problem", value: "technique" },
  { label: "Trail", value: "trail" },
  { label: "Workshop", value: "workshop" },
  { label: "Other", value: "autres" },
];

export const contactSubObject = [
  {
    label: "All workshops",
    value: "all_workshops",
  },
  {
    label: "Conscience workshops",
    value: "conscience",
  },
  {
    label: "Body workshops",
    value: "body",
  },
  {
    label: "Fun workshops",
    value: "fun",
  },
  {
    label: "Rituals workshops",
    value: "rituals",
  },
  {
    label: "Science workshops",
    value: "science",
  },
  {
    label: "Testimony workshops",
    value: "testimony",
  },
  {
    label: "Tools workshops",
    value: "tools",
  },
  {
    label: "Coaching workshops",
    value: "coaching",
  },
  { label: "Billing", value: "billing" },
  { label: "Payment", value: "payment" },
  {
    label: "Dysfunction",
    value: "dysfunction",
  },
  { label: "Suggestion", value: "suggestion" },
];

export const notifcationPlatformOptions = [
  { label: "All Devices", value: "all" },
  { label: "Android Devices", value: "android" },
  { label: "Ios Devices", value: "ios" },
  { label: "Web Devices", value: "web" },
];

export const notifcationTargetOptions = [
  { label: "All Users", value: "all" },
  { label: "Freemium Users", value: "fremium" },
  { label: "Paid Users", value: "paid_users" },
  { label: "Company Users", value: "company" },
  { label: "Canceled Subscription Users", value: "sub_canceled" },
  { label: "Hiker Users (Randonneur)", value: "Randonneur" },
  { label: "Explorer Users (Explorateur)", value: "Explorateur" },
  { label: "Sherpa Users", value: "Sherpa" },
];

export const countries = [
  { label: "Afghanistan", value: "AF" },
  { label: "Åland Islands", value: "AX" },
  { label: "Albania", value: "AL" },
  { label: "Algeria", value: "DZ" },
  { label: "American Samoa", value: "AS" },
  { label: "AndorrA", value: "AD" },
  { label: "Angola", value: "AO" },
  { label: "Anguilla", value: "AI" },
  { label: "Antarctica", value: "AQ" },
  { label: "Antigua and Barbuda", value: "AG" },
  { label: "Argentina", value: "AR" },
  { label: "Armenia", value: "AM" },
  { label: "Aruba", value: "AW" },
  { label: "Australia", value: "AU" },
  { label: "Austria", value: "AT" },
  { label: "Azerbaijan", value: "AZ" },
  { label: "Bahamas", value: "BS" },
  { label: "Bahrain", value: "BH" },
  { label: "Bangladesh", value: "BD" },
  { label: "Barbados", value: "BB" },
  { label: "Belarus", value: "BY" },
  { label: "Belgium", value: "BE" },
  { label: "Belize", value: "BZ" },
  { label: "Benin", value: "BJ" },
  { label: "Bermuda", value: "BM" },
  { label: "Bhutan", value: "BT" },
  { label: "Bolivia", value: "BO" },
  { label: "Bosnia and Herzegovina", value: "BA" },
  { label: "Botswana", value: "BW" },
  { label: "Bouvet Island", value: "BV" },
  { label: "Brazil", value: "BR" },
  { label: "British Indian Ocean Territory", value: "IO" },
  { label: "Brunei Darussalam", value: "BN" },
  { label: "Bulgaria", value: "BG" },
  { label: "Burkina Faso", value: "BF" },
  { label: "Burundi", value: "BI" },
  { label: "Cambodia", value: "KH" },
  { label: "Cameroon", value: "CM" },
  { label: "Canada", value: "CA" },
  { label: "Cape Verde", value: "CV" },
  { label: "Cayman Islands", value: "KY" },
  { label: "Central African Republic", value: "CF" },
  { label: "Chad", value: "TD" },
  { label: "Chile", value: "CL" },
  { label: "China", value: "CN" },
  { label: "Christmas Island", value: "CX" },
  { label: "Cocos (Keeling) Islands", value: "CC" },
  { label: "Colombia", value: "CO" },
  { label: "Comoros", value: "KM" },
  { label: "Congo", value: "CG" },
  { label: "Congo, The Democratic Republic of the", value: "CD" },
  { label: "Cook Islands", value: "CK" },
  { label: "Costa Rica", value: "CR" },
  { label: "Cote D'Ivoire", value: "CI" },
  { label: "Croatia", value: "HR" },
  { label: "Cuba", value: "CU" },
  { label: "Cyprus", value: "CY" },
  { label: "Czech Republic", value: "CZ" },
  { label: "Denmark", value: "DK" },
  { label: "Djibouti", value: "DJ" },
  { label: "Dominica", value: "DM" },
  { label: "Dominican Republic", value: "DO" },
  { label: "Ecuador", value: "EC" },
  { label: "Egypt", value: "EG" },
  { label: "El Salvador", value: "SV" },
  { label: "Equatorial Guinea", value: "GQ" },
  { label: "Eritrea", value: "ER" },
  { label: "Estonia", value: "EE" },
  { label: "Ethiopia", value: "ET" },
  { label: "Falkland Islands (Malvinas)", value: "FK" },
  { label: "Faroe Islands", value: "FO" },
  { label: "Fiji", value: "FJ" },
  { label: "Finland", value: "FI" },
  { label: "France", value: "FR" },
  { label: "French Guiana", value: "GF" },
  { label: "French Polynesia", value: "PF" },
  { label: "French Southern Territories", value: "TF" },
  { label: "Gabon", value: "GA" },
  { label: "Gambia", value: "GM" },
  { label: "Georgia", value: "GE" },
  { label: "Germany", value: "DE" },
  { label: "Ghana", value: "GH" },
  { label: "Gibraltar", value: "GI" },
  { label: "Greece", value: "GR" },
  { label: "Greenland", value: "GL" },
  { label: "Grenada", value: "GD" },
  { label: "Guadeloupe", value: "GP" },
  { label: "Guam", value: "GU" },
  { label: "Guatemala", value: "GT" },
  { label: "Guernsey", value: "GG" },
  { label: "Guinea", value: "GN" },
  { label: "Guinea-Bissau", value: "GW" },
  { label: "Guyana", value: "GY" },
  { label: "Haiti", value: "HT" },
  { label: "Heard Island and Mcdonald Islands", value: "HM" },
  { label: "Holy See (Vatican City State)", value: "VA" },
  { label: "Honduras", value: "HN" },
  { label: "Hong Kong", value: "HK" },
  { label: "Hungary", value: "HU" },
  { label: "Iceland", value: "IS" },
  { label: "India", value: "IN" },
  { label: "Indonesia", value: "ID" },
  { label: "Iran, Islamic Republic Of", value: "IR" },
  { label: "Iraq", value: "IQ" },
  { label: "Ireland", value: "IE" },
  { label: "Isle of Man", value: "IM" },
  { label: "Israel", value: "IL" },
  { label: "Italy", value: "IT" },
  { label: "Jamaica", value: "JM" },
  { label: "Japan", value: "JP" },
  { label: "Jersey", value: "JE" },
  { label: "Jordan", value: "JO" },
  { label: "Kazakhstan", value: "KZ" },
  { label: "Kenya", value: "KE" },
  { label: "Kiribati", value: "KI" },
  { label: "Korea, Democratic People'S Republic of", value: "KP" },
  { label: "Korea, Republic of", value: "KR" },
  { label: "Kuwait", value: "KW" },
  { label: "Kyrgyzstan", value: "KG" },
  { label: "Lao People'S Democratic Republic", value: "LA" },
  { label: "Latvia", value: "LV" },
  { label: "Lebanon", value: "LB" },
  { label: "Lesotho", value: "LS" },
  { label: "Liberia", value: "LR" },
  { label: "Libyan Arab Jamahiriya", value: "LY" },
  { label: "Liechtenstein", value: "LI" },
  { label: "Lithuania", value: "LT" },
  { label: "Luxembourg", value: "LU" },
  { label: "Macao", value: "MO" },
  { label: "Macedonia, The Former Yugoslav Republic of", value: "MK" },
  { label: "Madagascar", value: "MG" },
  { label: "Malawi", value: "MW" },
  { label: "Malaysia", value: "MY" },
  { label: "Maldives", value: "MV" },
  { label: "Mali", value: "ML" },
  { label: "Malta", value: "MT" },
  { label: "Marshall Islands", value: "MH" },
  { label: "Martinique", value: "MQ" },
  { label: "Mauritania", value: "MR" },
  { label: "Mauritius", value: "MU" },
  { label: "Mayotte", value: "YT" },
  { label: "Mexico", value: "MX" },
  { label: "Micronesia, Federated States of", value: "FM" },
  { label: "Moldova, Republic of", value: "MD" },
  { label: "Monaco", value: "MC" },
  { label: "Mongolia", value: "MN" },
  { label: "Montserrat", value: "MS" },
  { label: "Morocco", value: "MA" },
  { label: "Mozambique", value: "MZ" },
  { label: "Myanmar", value: "MM" },
  { label: "Namibia", value: "NA" },
  { label: "Nauru", value: "NR" },
  { label: "Nepal", value: "NP" },
  { label: "Netherlands", value: "NL" },
  { label: "Netherlands Antilles", value: "AN" },
  { label: "New Caledonia", value: "NC" },
  { label: "New Zealand", value: "NZ" },
  { label: "Nicaragua", value: "NI" },
  { label: "Niger", value: "NE" },
  { label: "Nigeria", value: "NG" },
  { label: "Niue", value: "NU" },
  { label: "Norfolk Island", value: "NF" },
  { label: "Northern Mariana Islands", value: "MP" },
  { label: "Norway", value: "NO" },
  { label: "Oman", value: "OM" },
  { label: "Pakistan", value: "PK" },
  { label: "Palau", value: "PW" },
  { label: "Palestinian Territory, Occupied", value: "PS" },
  { label: "Panama", value: "PA" },
  { label: "Papua New Guinea", value: "PG" },
  { label: "Paraguay", value: "PY" },
  { label: "Peru", value: "PE" },
  { label: "Philippines", value: "PH" },
  { label: "Pitcairn", value: "PN" },
  { label: "Poland", value: "PL" },
  { label: "Portugal", value: "PT" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Qatar", value: "QA" },
  { label: "Reunion", value: "RE" },
  { label: "Romania", value: "RO" },
  { label: "Russian Federation", value: "RU" },
  { label: "RWANDA", value: "RW" },
  { label: "Saint Helena", value: "SH" },
  { label: "Saint Kitts and Nevis", value: "KN" },
  { label: "Saint Lucia", value: "LC" },
  { label: "Saint Pierre and Miquelon", value: "PM" },
  { label: "Saint Vincent and the Grenadines", value: "VC" },
  { label: "Samoa", value: "WS" },
  { label: "San Marino", value: "SM" },
  { label: "Sao Tome and Principe", value: "ST" },
  { label: "Saudi Arabia", value: "SA" },
  { label: "Senegal", value: "SN" },
  { label: "Serbia and Montenegro", value: "CS" },
  { label: "Seychelles", value: "SC" },
  { label: "Sierra Leone", value: "SL" },
  { label: "Singapore", value: "SG" },
  { label: "Slovakia", value: "SK" },
  { label: "Slovenia", value: "SI" },
  { label: "Solomon Islands", value: "SB" },
  { label: "Somalia", value: "SO" },
  { label: "South Africa", value: "ZA" },
  { label: "South Georgia and the South Sandwich Islands", value: "GS" },
  { label: "Spain", value: "ES" },
  { label: "Sri Lanka", value: "LK" },
  { label: "Sudan", value: "SD" },
  { label: "Suriname", value: "SR" },
  { label: "Svalbard and Jan Mayen", value: "SJ" },
  { label: "Swaziland", value: "SZ" },
  { label: "Sweden", value: "SE" },
  { label: "Switzerland", value: "CH" },
  { label: "Syrian Arab Republic", value: "SY" },
  { label: "Taiwan, Province of China", value: "TW" },
  { label: "Tajikistan", value: "TJ" },
  { label: "Tanzania, United Republic of", value: "TZ" },
  { label: "Thailand", value: "TH" },
  { label: "Timor-Leste", value: "TL" },
  { label: "Togo", value: "TG" },
  { label: "Tokelau", value: "TK" },
  { label: "Tonga", value: "TO" },
  { label: "Trinidad and Tobago", value: "TT" },
  { label: "Tunisia", value: "TN" },
  { label: "Turkey", value: "TR" },
  { label: "Turkmenistan", value: "TM" },
  { label: "Turks and Caicos Islands", value: "TC" },
  { label: "Tuvalu", value: "TV" },
  { label: "Uganda", value: "UG" },
  { label: "Ukraine", value: "UA" },
  { label: "United Arab Emirates", value: "AE" },
  { label: "United Kingdom", value: "GB" },
  { label: "United States", value: "US" },
  { label: "United States Minor Outlying Islands", value: "UM" },
  { label: "Uruguay", value: "UY" },
  { label: "Uzbekistan", value: "UZ" },
  { label: "Vanuatu", value: "VU" },
  { label: "Venezuela", value: "VE" },
  { label: "Viet Nam", value: "VN" },
  { label: "Virgin Islands, British", value: "VG" },
  { label: "Virgin Islands, U.S.", value: "VI" },
  { label: "Wallis and Futuna", value: "WF" },
  { label: "Western Sahara", value: "EH" },
  { label: "Yemen", value: "YE" },
  { label: "Zambia", value: "ZM" },
  { label: "Zimbabwe", value: "ZW" },
];



export const typeNotificationOptions = [
  {
    label: "One use (this allow you to only send the notification manually)",
    value: "one_use",
  },
  {
    label:
      "Random (will include the notification in the pool of messages sent periodically. These automated notifications will be delivered at predefined intervals and randomly)",
    value: "random",
  },
];

export const NotifSendType = [
  {
    label: "One use",
    value: "one_use",
  },
  {
    label: "Random",
    value: "random",
  },
];

export const companiesFilesTypes = [
  { label: "Invoice", value: "invoice" },
  { label: "Agreement", value: "agreement" },
];

export const questionType = [
  { label: "Free Text Answer", value: "free" },
  { label: "QCU - Single answer allowed", value: "qcu" },

];

export const sectionsOptions = [
  { value: 'all', label: 'All Sections' },
  { value: 'section1', label: 'Estime de soi' },
  { value: 'section2', label: 'Equilibre' },
  { value: 'section3', label: 'Contrôle de soi et des évènements' },
  { value: 'section4', label: 'Emotions, Stress et anxiété' },
  { value: 'section5', label: 'Relations sociales' },
  { value: 'section6', label: 'Sociabilité' },
  { value: 'section7', label: 'Ressources financières & Pratiques de consommation' },
  { value: 'section8', label: 'Physique' },
  { value: 'section9', label: 'Sens' },
  { value: 'section10', label: 'Autonomie dans le travail' },
  { value: 'section11', label: 'Management' },
  { value: 'section12', label: 'Equipe/clients' },
  { value: 'section13', label: 'Alignement' },
  { value: 'section14', label: 'Stress' },
]

export const sectionQuestionType = [
  { label: 'Degree Input', value: 'qcu' }, { label: 'Select Input', value: 'select' },
]

export const reportingOptions = [
  { label: "Stress", value: "T004" },
  { label: "Emotional", value: "T005" }
  /*{ label: "Harassment", value: "" },
  { label: "Discrimination", value: "" },
  { label: "Burnout", value: "" },*/
];

export const dominosPieces = [
  { up: 0, down: 0 },
  { up: 0, down: 1 },
  { up: 0, down: 2 },
  { up: 0, down: 3 },
  { up: 0, down: 4 },
  { up: 0, down: 5 },
  { up: 0, down: 6 },
  { up: 0, down: 7 },
  { up: 0, down: 8 },
  { up: 0, down: 9 },

  { up: 1, down: 0 },
  { up: 1, down: 1 },
  { up: 1, down: 2 },
  { up: 1, down: 3 },
  { up: 1, down: 4 },
  { up: 1, down: 5 },
  { up: 1, down: 6 },
  { up: 1, down: 7 },
  { up: 1, down: 8 },
  { up: 1, down: 9 },

  { up: 2, down: 0 },
  { up: 2, down: 1 },
  { up: 2, down: 2 },
  { up: 2, down: 3 },
  { up: 2, down: 4 },
  { up: 2, down: 5 },
  { up: 2, down: 6 },
  { up: 2, down: 7 },
  { up: 2, down: 8 },
  { up: 2, down: 9 },

  { up: 3, down: 0 },
  { up: 3, down: 1 },
  { up: 3, down: 2 },
  { up: 3, down: 3 },
  { up: 3, down: 4 },
  { up: 3, down: 5 },
  { up: 3, down: 6 },
  { up: 3, down: 7 },
  { up: 3, down: 8 },
  { up: 3, down: 9 },

  { up: 4, down: 0 },
  { up: 4, down: 1 },
  { up: 4, down: 2 },
  { up: 4, down: 3 },
  { up: 4, down: 4 },
  { up: 4, down: 5 },
  { up: 4, down: 6 },
  { up: 4, down: 7 },
  { up: 4, down: 8 },
  { up: 4, down: 9 },


  { up: 5, down: 0 },
  { up: 5, down: 1 },
  { up: 5, down: 2 },
  { up: 5, down: 3 },
  { up: 5, down: 4 },
  { up: 5, down: 5 },
  { up: 5, down: 6 },
  { up: 5, down: 7 },
  { up: 5, down: 8 },
  { up: 5, down: 9 },

  { up: 6, down: 0 },
  { up: 6, down: 1 },
  { up: 6, down: 2 },
  { up: 6, down: 3 },
  { up: 6, down: 4 },
  { up: 6, down: 5 },
  { up: 6, down: 6 },
  { up: 6, down: 7 },
  { up: 6, down: 8 },
  { up: 6, down: 9 },

  { up: 7, down: 0 },
  { up: 7, down: 1 },
  { up: 7, down: 2 },
  { up: 7, down: 3 },
  { up: 7, down: 4 },
  { up: 7, down: 5 },
  { up: 7, down: 6 },
  { up: 7, down: 7 },
  { up: 7, down: 8 },
  { up: 7, down: 9 },

  { up: 8, down: 0 },
  { up: 8, down: 1 },
  { up: 8, down: 2 },
  { up: 8, down: 3 },
  { up: 8, down: 4 },
  { up: 8, down: 5 },
  { up: 8, down: 6 },
  { up: 8, down: 7 },
  { up: 8, down: 8 },
  { up: 8, down: 9 },

  { up: 9, down: 0 },
  { up: 9, down: 1 },
  { up: 9, down: 2 },
  { up: 9, down: 3 },
  { up: 9, down: 4 },
  { up: 9, down: 5 },
  { up: 9, down: 6 },
  { up: 9, down: 7 },
  { up: 9, down: 8 },
  { up: 9, down: 9 },
]