import GlobalStyles, {
  AppWrapper,
  BodyFooterWrapper,
  BodyOne,
  BodyTwo,
} from "./globalStyles";
import "./i18n";
import { Route, useHistory, useLocation } from "react-router-dom";
import Header from "./components/header";
import React, { Suspense, useEffect, useState } from "react";
import ScrollToTop from "./ScrollToTop";
import Footer from "./components/footer";
import Home from "./pages/home/home";
import NewTrail from "./pages/trails/newTrail";
import NewWorkshop from "./pages/workshops/newWorkshop";
import UpdateWorkshop from "./pages/workshops/updateWorkshop";
import Trails from "./pages/trails/trails";
import Workshops from "./pages/workshops/workshops";
import Account from "./pages/account";
import UpdateTrail from "./pages/trails/updateTrail";
import NewSeason from "./pages/seasons/newSeason";
import UpdateSeason from "./pages/seasons/updateSeason";
import NewEpisode from "./pages/episodes/newEpisode";
import UpdateEpisode from "./pages/episodes/updateEpisode";
import { ThemeProvider } from "@mui/material";
import { theme } from "./themes/materialTheme";
import Login from "./pages/login";
import NewExpert from "./pages/experts/newExpert";
import DropdownMenu from "./components/dropdown";
import Experts from "./pages/experts/experts";
import UpdateExpert from "./pages/experts/updateExpert";
import ExpertRessources from "./pages/experts/expertRessources";
import Books from "./pages/books/books";
import UpdateBook from "./pages/books/updateBook";
import NewBook from "./pages/books/newBook";
import Companies from "./pages/companies/companies";
import AppUsers from "./pages/app-users/appUsers";
import NewCompany from "./pages/companies/newCompany";
import UpdateCompany from "./pages/companies/updateCompany";
import AppUser from "./pages/app-users/appUser";

import Contacts from "./pages/contacts";
import ContactItem from "./pages/contacts/contactItem";
import Users from "./pages/users";
import Messages from "./pages/messages/messages";
import NewMessage from "./pages/messages/newMessage";
import UpdateMessage from "./pages/messages/updateMessage";
import Appointments from "./pages/coaching-appointments/appointments";
import Appointment from "./pages/coaching-appointments/Appointment";
import "react-datepicker/dist/react-datepicker.css";
import "./styles/date-time.css";
import Posts from "./pages/community/posts";
import Post from "./pages/community/post";
import FAQs from "./pages/faq/faqs";
import FAQ from "./pages/faq/faq";
import AddFAQ from "./pages/faq/addFAQ";
import AddUser from "./pages/users/addUser";
import User from "./pages/users/user";
import axios from "axios";
import Association from "./pages/association/association";
import NewAssociation from "./pages/association/newAssociation";
import UpdateAssociation from "./pages/association/updateAssociation";

import ExpertHome from "./pages/home/ExpertHome";
import ExpertAppointments from "./pages/coaching-appointments/expert-appointments/Appointments";
import ExpertAppointment from "./pages/coaching-appointments/expert-appointments/Appointment";
import Gifts from "./pages/gifts/gifts";
import ExpertPayement from "./pages/experts/expertPayement";
import ForgotPassword from "./pages/forgotPassword";
import NewNotification from "./pages/notifications/newNotification";
import UpdateNotification from "./pages/notifications/updateNotification";
import Notifications from "./pages/notifications/notifications";
import Invoices from "./pages/invoices/Invoices";
import Chat from "./pages/chat";
import CompanyStat from "./pages/companies/CompanyStat";
import Questionnaires from "./pages/questionnaires/questionnaires";
import NewQuestionnaire from "./pages/questionnaires/newQuestionnaire";
import UpdateQuestionnaire from "./pages/questionnaires/updateQuestionnaire";
import UpdateQuestion from "./pages/questionnaires/updateQuestion";
import GroupStat from "./pages/companies/GroupStat";
import Stats from "./pages/stats/Stats";
import Report from "./pages/companies/ReportExample/Report";
import Dominos from "./pages/games/dominos/dominos";
import NewDomino from "./pages/games/dominos/newDomino";
import BilanAnalysis from "./pages/bilan/bilanAnalysis";
import UpdateBilan from "./pages/bilan/update-bilan";
import CompanyUsers from "./pages/app-users/companyUsers";
import GroupUsers from "./pages/app-users/groupUsers";
import Section from "./pages/sections/Section";
import Compagne from "./pages/companies/Compagne";
import BilanQuestions from "./pages/bilan/bilanQuestions";

export const UserContext = React.createContext(null);

function App() {
  const TOKEN = localStorage.getItem("TOKEN");
  const path = useLocation().pathname;
  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const checkUser = async () => {
      if (!TOKEN) {
        setLoader(false);
        if (path !== "/forgot-password") {
          history.replace("/signin");
        }
      } else {
        try {
          // GET USER PROFILE
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${TOKEN}`,
            },
          };
          const response = await axios.get(
            process.env.REACT_APP_DASHBOARD_PROFILE_USER,
            config
          );
          setUser(response.data);
          // console.log(response.data);
          setLoader(false);
        } catch (error) {
          console.log(error);
          localStorage.removeItem("TOKEN");
          history.replace("/signin");
        }
      }
      // console.log(path);
    };
    checkUser();
  }, [path, TOKEN, history]);

  if (loader) {
    return <div></div>;
  }

  return (
    <UserContext.Provider value={{ user: user, setUser: setUser }}>
      <ThemeProvider theme={theme}>
        <AppWrapper>
          <GlobalStyles />
          <ScrollToTop />
          <Suspense fallback={null}>
            {path !== "/signin" && <Header />}
            <BodyFooterWrapper>
              <BodyOne ignore={path === "/signin"}>
                <BodyTwo />
                <Route exact path="/signin">
                  <Login />
                </Route>
                <Route exact path="/forgot-password">
                  <ForgotPassword />
                </Route>

                <Route exact path="/">
                  {user ? (
                    user.role === "expert" ? (
                      <ExpertHome />
                    ) : (
                      <Home />
                    )
                  ) : (
                    <></>
                  )}
                </Route>
                <Route exact path="/stats">
                  <Stats />
                </Route>
                <Route exact path="/workshops">
                  <Workshops />
                </Route>
                <Route exact path="/new-workshop">
                  <NewWorkshop />
                </Route>
                <Route exact path="/workshop/:workshop_code">
                  <UpdateWorkshop />
                </Route>
                <Route exact path="/trails">
                  <Trails />
                </Route>
                <Route exact path="/new-trail">
                  <NewTrail />
                </Route>
                <Route exact path="/trail/:trail_code">
                  <UpdateTrail />
                </Route>
                <Route exact path="/new-season/:trail_code">
                  <NewSeason />
                </Route>
                <Route exact path="/season/:season_code">
                  <UpdateSeason />
                </Route>
                <Route exact path="/new-episode/:season_code">
                  <NewEpisode />
                </Route>
                <Route exact path="/episode/:episode_code">
                  <UpdateEpisode />
                </Route>
                <Route exact path="/account/:id">
                  <Account />
                </Route>
                <Route exact path="/experts">
                  <Experts />
                </Route>
                <Route exact path="/expert/:expert_id">
                  <UpdateExpert />
                </Route>
                <Route exact path="/expert/:expert_id/ressources">
                  <ExpertRessources />
                </Route>
                <Route exact path="/new-expert">
                  <NewExpert />
                </Route>
                <Route exact path="/books">
                  <Books />
                </Route>
                <Route exact path="/book/:id">
                  <UpdateBook />
                </Route>
                <Route exact path="/new-book">
                  <NewBook />
                </Route>
                <Route exact path="/companies">
                  <Companies />
                </Route>
                <Route exact path="/report-example">
                  <Report />
                </Route>
                <Route exact path="/company-stats/:company_id">
                  <CompanyStat />
                </Route>
                <Route exact path="/group-stats/:group_id">
                  <GroupStat />
                </Route>
                <Route exact path="/new-company">
                  <NewCompany />
                </Route>
                <Route exact path="/company/:company_id">
                  <UpdateCompany />
                </Route>
                <Route exact path="/compaign/:compagne_id">
                  <Compagne />
                </Route>
                <Route exact path="/app-users">
                  <AppUsers />
                </Route>
                <Route exact path="/company-users/:company">
                  <CompanyUsers />
                </Route>
                <Route exact path="/group-users/:group">
                  <GroupUsers />
                </Route>
                <Route exact path="/app-user/:user_id">
                  <AppUser />
                </Route>
                <Route exact path="/contact">
                  <Contacts />
                </Route>
                <Route exact path="/invoices">
                  <Invoices />
                </Route>
                <Route exact path="/contact/:id">
                  <ContactItem />
                </Route>
                <Route exact path="/users">
                  <Users />
                </Route>
                <Route exact path="/add-user">
                  <AddUser />
                </Route>
                <Route exact path="/user/:user_id">
                  <User />
                </Route>

                <Route exact path="/monthly-messages">
                  <Messages />
                </Route>
                <Route exact path="/new-monthly-message">
                  <NewMessage />
                </Route>
                <Route exact path="/update-monthly-message/:message_id">
                  <UpdateMessage />
                </Route>

                <Route exact path="/notifications">
                  <Notifications />
                </Route>
                <Route exact path="/new-notification">
                  <NewNotification />
                </Route>
                <Route exact path="/update-notification/:notification_id">
                  <UpdateNotification />
                </Route>

                <Route exact path="/questionnaires">
                  <Questionnaires />
                </Route>
                <Route exact path="/bilan-analysis">
                  <BilanAnalysis />
                </Route>
                <Route exact path="/bilan-questions">
                  <BilanQuestions />
                </Route>
                <Route exact path="/update-bilan-analysis/:section">
                  <UpdateBilan />
                </Route>
                <Route exact path="/section/:section_id">
                  <Section />
                </Route>
                <Route exact path="/update-question/:question_id">
                  <UpdateQuestion />
                </Route>
                <Route exact path="/new-questionnaire">
                  <NewQuestionnaire />
                </Route>
                <Route exact path="/update-questionnaire/:questionnaire_id">
                  <UpdateQuestionnaire />
                </Route>

                <Route exact path="/coaching-appointments">
                  {user ? (
                    user.role === "expert" ? (
                      <ExpertAppointments />
                    ) : (
                      <Appointments />
                    )
                  ) : (
                    <></>
                  )}
                </Route>
                <Route exact path="/appointment/:appointment_id">
                  {user ? (
                    user.role === "expert" ? (
                      <ExpertAppointment />
                    ) : (
                      <Appointment />
                    )
                  ) : (
                    <></>
                  )}
                </Route>
                <Route exact path="/expert-payment">
                  <ExpertPayement />
                </Route>
                <Route exact path="/posts">
                  <Posts />
                </Route>
                <Route exact path="/post/:post_id">
                  <Post />
                </Route>
                <Route exact path="/faqs">
                  <FAQs />
                </Route>
                <Route exact path="/faq/:faq_id">
                  <FAQ />
                </Route>
                <Route exact path="/add-faq">
                  <AddFAQ />
                </Route>
                <Route exact path="/association">
                  <Association />
                </Route>
                <Route exact path="/new-association">
                  <NewAssociation />
                </Route>
                <Route exact path="/update-association/:association_id">
                  <UpdateAssociation />
                </Route>
                <Route exact path="/gifts">
                  <Gifts />
                </Route>
                <Route exact path="/chat">
                  <Chat />
                </Route>
                <Route exact path="/dominos">
                  <Dominos />
                </Route>

                <Route exact path="/new-domino">
                  <NewDomino />
                </Route>
              </BodyOne>
              {path !== "/signin" && <Footer />}
            </BodyFooterWrapper>
          </Suspense>
          <Suspense fallback={null}>
            <DropdownMenu />
          </Suspense>
        </AppWrapper>
      </ThemeProvider>
    </UserContext.Provider>
  );
}

export default App;
